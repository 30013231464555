import { Injectable } from '@angular/core';
import { AppConfig } from 'client/app/app.config';
import { OrderHelper } from 'client/helper/order-helper';

@Injectable({ providedIn: 'root' })
export class TakePhotoBannerService {
  bannerTriggerStates: Record<string, boolean> = {
    orderWaitingForImage: false,
    orderPlacedWithoutCheckup: false,
    firstRegimenWithoutCheckup: false,
  };

  constructor(
    private readonly appConfig: AppConfig,
    private readonly orderHelper: OrderHelper,
  ) {
  }

  public shouldRenderBanner(instantCheckups: any, regimens: any, activeOrders: any): boolean {
    this.resetBannerTriggeringStates();
    const regimenOrders = this.orderHelper.filterOrdersByType(activeOrders, 'REGIMEN');
    const firstRegimenOrder = regimenOrders[0];
    const firstRegimen = regimens[0];

    if (this.isOrderStageWaitingForImage(firstRegimenOrder, instantCheckups)) {
      this.bannerTriggerStates.orderWaitingForImage = true;
      return true;
    }

    if (this.isOrderInProgressWithoutCheckups(firstRegimenOrder, instantCheckups)) {
      this.bannerTriggerStates.orderPlacedWithoutCheckup = true;
      return true;
    }

    if (this.isFirstRegimenWithoutCheckup(firstRegimen, instantCheckups)) {
      this.bannerTriggerStates.firstRegimenWithoutCheckup = true;
      return true;
    }

    return false;
  }

  private isFirstRegimenWithoutCheckup(firstRegimen: any, instantCheckups: any): boolean {
    return firstRegimen && instantCheckups.length < 1;
  }

  private isOrderStageWaitingForImage(firstRegimenOrder: any, instantCheckups: any): boolean {
    return firstRegimenOrder?.get('stage') === this.appConfig.Shared.Order.Stage.WAITING_FOR_IMAGE && instantCheckups.length < 1;
  }

  private isOrderInProgressWithoutCheckups(firstRegimenOrder: any, instantCheckups: any): boolean {
    const stagesExcludingWFI = [
      ...this.appConfig.Shared.Order.Stage.orderPlacedStages,
      ...this.appConfig.Shared.Order.Stage.orderPackedStages,
      ...this.appConfig.Shared.Order.Stage.orderDispatchedStages,
      ...this.appConfig.Shared.Order.Stage.orderDeliveryFailedStages,
      ...this.appConfig.Shared.Order.Stage.orderOutForDeliveryStages,
    ].filter((stage:string):boolean => stage !== this.appConfig.Shared.Order.Stage.WAITING_FOR_IMAGE);

    return stagesExcludingWFI.includes(firstRegimenOrder?.get('stage')) && instantCheckups.length < 1;
  }

  public getBannerTriggeringStates():Record<string, boolean> {
    return this.bannerTriggerStates;
  }

  private resetBannerTriggeringStates(): void {
    Object.keys(this.bannerTriggerStates).forEach((key:string):any => {
      this.bannerTriggerStates[key] = false;
    });
  }
}
