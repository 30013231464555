<div class="font-noto trans-x-hide tw-h-full" swipe="swipe" (coordinates)="swipeCoordinates($event)" [ngClass]="{'trans-xy-0': isTabActive }">
  <div class="tw-flex flex-col tw-bg-white tw-h-full" #homePageContainer="">
    <!--nav bar-->
    <div class="tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-10 tw-flex tw-flex-row tw-items-center tw-gap-2 tw-bg-transparent tw-px-4 tw-py-4">
      <div class="tw-flex-none tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl tw-h-10 tw-w-10 cs-icon-menu" (click)="toggleMenu(true)">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'menu'"></cs-icon>
      </div>
      <div class="tw-flex-1"></div>
      <div class="tw-flex-none tw-flex tw-items-center tw-justify-center tw-gap-2 tw-px-4 tw-rounded-full tw-h-10 tw-border-2 tw-border-white tw-bg-teal-200" *ngIf="showCashBalance" (click)="openBottomSheetForCash()">
        <cs-icon class="tw-h-6 tw-w-6" [iconName]="'cureskin_cash_coin'"></cs-icon>
        <p class="tw-font-body tw-font-bold tw-text-400 tw-pt-[2px]">
          {{totalCashBalance}}
        </p>
      </div>
      <div class="tw-flex-none tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl tw-h-10 tw-w-10 tw-relative" *ngIf="toolbarIcons[1].cartValue && userStatus.PAID" (click)="goToCart()">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'shopping_cart'"></cs-icon>
        <span class="tw-absolute tw-top-[2px] tw-right-[18%] tw-bg-gray-800 tw-font-body tw-font-bold tw-h-[14px] tw-text-200 tw-text-red-400">{{toolbarIcons[1]?.cartValue}}</span>
      </div>
      <div class="tw-flex-none tw-relative tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-xl tw-h-10 tw-w-10" (click)="notificationClickAction()" [ngClass]='unreadNotifications ? "notify-icon-new" : ""'>
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'notifications'"></cs-icon>
      </div>
    </div>
    <!--loading-->
    <div class="shell tw-flex-1 tw-pt-14" *ngIf="loading">
      <div class="welcome">
        <div class="title shimmer"></div>
        <div class="desc shimmer"></div>
      </div>
      <section class="three">
        <div class="cardHolder">
          <div class="card shimmer" *ngFor="let i of [1,2,3]"></div>
        </div>
      </section>
      <section *ngFor="let i of [1,2]">
        <div class="header">
          <div class="title shimmer"></div>
          <div class="desc shimmer"></div>
        </div>
        <div class="cardHolder">
          <div class="card shimmer"></div>
          <div class="card shimmer"></div>
        </div>
      </section>
    </div>
    <div class="content tw-flex-1 tw-bg-gray-80 tw-overflow-y-auto tw-overflow-x-hidden" *ngIf="!loading">
      <div class="tw-flex tw-flex-col tw-bg-teal-200 tw-bg-repeat-x tw-bg-top tw-pt-14 tw-px-0 tw-overflow-visible tw-mb-20 tw-pb-0">
        <!--Three icons in header(Unpaid state)-->
        <div class="tw-mt-12 three-icons tw-px-4 tw-relative tw-left-0 tw-right-0 tw-mb-[-56px]">
          <div class="tw-grid tw-grid-cols-2 tw-gap-2" *ngIf="!userStatus.PAID">
            <div class="fading-grid-pattern tw-bg-gray-50 tw-px-4 tw-py-2 tw-rounded-[15px] tw-flex-1" (click)="trackEventAndNavigateTo('HOME_FACE', '/user?tab=regimen&amp;class=FACE')">
              <img class="tw-max-h-[56px]" [src]="faceIconUrl" />
              <div class="tw-flex tw-justify-between tw-items-center tw-gap-2 fw-500 font14 tw-mt-2">
                <span class="text face tw-text-300 tw-text-gray-800" i18n="@@faceTreatment">Face Treatment</span>
                <em class="cs-icon-arrow font10 fw-700 tw-text-gray-800"></em>
              </div>
            </div>
            <div class="fading-grid-pattern tw-bg-gray-50 tw-px-4 tw-py-2 tw-rounded-[15px] tw-flex-1" (click)="trackEventAndNavigateTo('HOME_HAIR', '/user?tab=regimen&amp;class=HAIR')">
              <img class="tw-max-h-[56px]" [src]="hairIconUrl" />
              <div class="tw-flex tw-justify-between tw-items-center tw-gap-2 fw-500 font14 tw-mt-2">
                <span class="text hair tw-text-300 tw-text-gray-800" i18n="@@hairTreatment">Hair Treatment</span>
                <em class="cs-icon-arrow font10 fw-700 tw-text-gray-800"></em>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-row tw-gap-2" *ngIf="userStatus.PAID && !isRebrandingHomeIcon">
            <div class="tw-flex-1 card tw-px-3 tw-pt-2 tw-pb-3 tw-rounded-2xl" [ngClass]="{'tw-break-all': [appConfig.Shared.Languages.ML, appConfig.Shared.Languages.KN, appConfig.Shared.Languages.TE, appConfig.Shared.Languages.TA].includes(user.get('languagePreference'))}" (click)="trackEventAndNavigateTo('HOME_INSTRUCTIONS', '/user?tab=regimen&fromHowToUse=true')">
              <img class="rounded mB10 flex-col tw-items-center" src="https://cdn.cureskin.com/app/img/home_how_to_use.png" />
              <div class="tw-font-body tw-font-bold tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@howToUse"> How to use </div>
            </div>
            <div class="tw-flex-1 card tw-px-3 tw-pt-2 tw-pb-3 tw-rounded-2xl" [ngClass]="{'tw-break-all': [appConfig.Shared.Languages.ML, appConfig.Shared.Languages.KN, appConfig.Shared.Languages.TE, appConfig.Shared.Languages.TA].includes(user.get('languagePreference')), 'notify notify-new': tabUpdates.FOLLOWUP}" (click)="trackEventAndNavigateTo('HOME_NEED_CHECKUP', '/user/checkup')">
              <img class="rounded mB10" src="https://cdn.cureskin.com/app/img/home_checkup.png" />
              <div class="tw-font-body tw-font-bold tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@doctorCheckup"> Doctor Checkup </div>
            </div>
            <div class="tw-flex-1 card tw-px-3 tw-pt-2 tw-pb-3 tw-rounded-2xl" [ngClass]="{'tw-break-all': [appConfig.Shared.Languages.ML, appConfig.Shared.Languages.KN, appConfig.Shared.Languages.TE, appConfig.Shared.Languages.TA].includes(user.get('languagePreference')), 'notify notify-new': tabUpdates.FOLLOWUP}" (click)="trackEventAndNavigateTo('HOWE_SHOP', '/user?tab=shop')">
              <img class="rounded mB10" src="https://cdn.cureskin.com/app/img/home_shop.gif" />
              <div class="tw-font-body tw-font-bold tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@shopProducts"> Shop products </div>
            </div>
            <div class="tw-flex-1 card tw-px-3 tw-pt-2 tw-pb-3 tw-rounded-2xl" [ngClass]="{'tw-break-all': [appConfig.Shared.Languages.ML, appConfig.Shared.Languages.KN, appConfig.Shared.Languages.TE, appConfig.Shared.Languages.TA].includes(user.get('languagePreference')), 'notify notify-new': tabUpdates.FOLLOWUP}" (click)="trackEventAndNavigateTo('HOME_NEED_HELP', '/support')">
              <img class="rounded mB10" src="https://cdn.cureskin.com/app/img/home_need_help.png" />
              <div class="tw-font-body tw-font-bold tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@needHelp">Need help</div>
            </div>
          </div>

          <div class="tw-flex tw-flex-row tw-justify-center tw-items-stretch tw-gap-2" *ngIf="userStatus.PAID && isRebrandingHomeIcon">
            <div class="tw-flex-grow tw-h-[104px] tw-w-[76px] tw-flex tw-flex-col tw-items-center tw-px-[10px] tw-pt-2 tw-pb-3 tw-rounded-2xl card"
                 [ngClass]="{'tw-break-all tw-text-[6px]': enableNgClassCondition() }"
                 (click)="trackEventAndNavigateTo('HOME_INSTRUCTIONS', '/user?tab=regimen&fromHowToUse=true')">
              <cs-icon class="tw-mb-2 tw-h-[52px] tw-w-[52px]" [iconName]="'home_how_to_use'"></cs-icon>
              <div class="tw-font-body tw-font-medium tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@howToUse"
                   [ngClass]="{'tw-break-all tw-text-[10px]': enableNgClassCondition() }"
              >
                How to use
              </div>
            </div>
            <div class="tw-flex-grow tw-h-[104px] tw-w-[76px] tw-flex tw-flex-col tw-items-center tw-px-[10px] tw-pt-2 tw-pb-3 tw-rounded-2xl card"
                 [ngClass]="{'tw-break-all tw-text-[12px]': enableNgClassCondition(), 'notify notify-new': tabUpdates?.FOLLOWUP}" (click)="trackEventAndNavigateTo('HOME_NEED_CHECKUP', '/user/checkup')">
              <cs-icon class="tw-mb-2 tw-h-[52px] tw-w-[52px]" [iconName]="'home_doctor_checkup'"></cs-icon>
              <div class="tw-font-body tw-font-medium tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@doctorCheckup"> Doctor Checkup </div>
            </div>
            <div class="tw-flex-grow tw-h-[104px] tw-w-[76px] tw-flex tw-flex-col tw-items-center tw-px-[10px] tw-pt-2 tw-pb-3 tw-rounded-2xl card"
                 [ngClass]="{'tw-break-all tw-text-[12px]': enableNgClassCondition(), 'notify notify-new': tabUpdates?.FOLLOWUP}" (click)="trackEventAndNavigateTo('HOWE_SHOP', '/user?tab=shop')">
              <img class="tw-mb-2 tw-h-[52px] tw-w-[52px]" src="/assets/icons/home_shop.gif" />
              <div class="tw-font-body tw-font-medium tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@shopProducts"> Shop products </div>
            </div>
            <div class="tw-flex-grow tw-h-[104px] tw-w-[76px] tw-flex tw-flex-col tw-items-center tw-px-[10px] tw-pt-2 tw-pb-3 tw-rounded-2xl card"
                 [ngClass]="{'tw-break-all tw-text-[12px]': enableNgClassCondition(), 'notify notify-new': tabUpdates?.FOLLOWUP}" (click)="trackEventAndNavigateTo('HOME_NEED_HELP', '/support')">
              <cs-icon class="tw-mb-2 tw-h-[52px] tw-w-[52px]" [iconName]="'home_help'"></cs-icon>
              <div class="tw-font-body tw-font-medium tw-text-black tw-text-200 tw-text-center tw-tracking-0 tw-leading-none" i18n="@@needHelp">Need help</div>
            </div>
          </div>
        </div>
      </div>
      <swiper #swiperCardsBanner="" [config]="carouselConfig">
        <ng-container *ngFor="let regimen of regimens; let i = index">
          <ng-container *ngIf="regimen.isPreparationTimePending">
            <div *swiperSlide>
              <regimen-preparation-banner [regimenClass]="regimen.class"></regimen-preparation-banner>
            </div>
          </ng-container>
        </ng-container>
      </swiper>
      <div class="tw-relative tw-mb-2">
        <swiper [config]="carouselConfig" id="order-banner-swiper" class="tw-max-w-full tw-overflow-visible">
          <ng-container *ngFor="let order of allActiveOrders">
            <ng-container *ngIf="order.get('stage') === appConfig.Shared.Order.Stage.DELIVERED && order.get('showOrderDeliveredBanner') && !isRebrandingBanner">
              <section class="tw-mx-4 tw-mb-4 bdr-rad-15 explore tw-p-4 tw-shadow-z1" *swiperSlide routerLink="/user/order/{{order?.id}}" (click)="addEvents()">
                <div class="header tw-flex tw-items-center">
                  <img class="tw-flex-none tw-h-12 tw-w-12 tw-rounded-full tw-mr-3" src="/assets/images/delivered.gif" />
                  <div class="tw-font-body tw-font-bold tw-text-400 tw-mr-2 tw-items-center tw-flex w50 tw-min-h-12" i18n="@@kitDelivered" *ngIf='order.get("type") === appConfig.Shared.Order.Type.REGIMEN'> We have delivered your treatment kit </div>
                  <div class="tw-font-body tw-font-bold tw-text-400 tw-mr-2 tw-items-center tw-flex w50 tw-min-h-12" i18n="@@orderDelivered" *ngIf='order.get("type") !== appConfig.Shared.Order.Type.REGIMEN'> We have delivered your order </div>
                  <div class="click-icon tw-border tw-border-gray-500 tw-rounded-full tw-w-8 tw-h-8 tw-absolute tw-right-2">
                    <cs-icon class="tw-absolute" [iconName]="'chevron_right_green'"></cs-icon>
                  </div>
                </div>
              </section>
            </ng-container>
            <ng-container *ngIf="order.get('stage') === appConfig.Shared.Order.Stage.DELIVERED && order.get('showOrderDeliveredBanner') && isRebrandingBanner" >
              <section class="tw-rounded-3 tw-mx-4 tw-mb-4 tw-px-2 tw-py-3 tw-border-[2px] tw-border-solid tw-border-teal-200 tw-max-h-18 tw-box-border"
                       style="background: linear-gradient(95deg, #E4F4F0 -6.23%, #FFF 88%);" *swiperSlide routerLink="/user/order/{{order?.id}}" (click)="addEvents()">
                <div class="header tw-flex tw-items-center">
                  <div >
                    <cs-icon class="tw-h-12 tw-w-12" [iconName]="'order_delivered'"></cs-icon>
                  </div>
                  <div class="tw-ml-2 tw-flex tw-flex-col tw-min-h-12 tw-gap-1">
                    <div class="tw-leading-4 tw-font-body tw-font-bold tw-text-[14px] tw-items-center tw-flex tw-pt-1" i18n="@@orderDeliveredTag">
                      Order Delivered
                      <cs-icon class="tw-h-5 tw-w-5 tw-pl-2" [iconName]="'chevron_right_teal'"></cs-icon>
                    </div>
                    <div class="tw-leading-[18px] tw-font-body tw-text-gray-500 tw-font-medium tw-text-[12px] tw-items-center tw-flex" i18n="@@yourOrderIsDelivered" *ngIf='order.get("type") !== appConfig.Shared.Order.Type.REGIMEN'>
                      Your order is Delivered
                    </div>
                    <div class="tw-leading-[18px] tw-font-body tw-text-gray-500 tw-font-medium tw-text-[12px] tw-items-center tw-flex" i18n="@@yourKitIsDelivered" *ngIf='order.get("type") === appConfig.Shared.Order.Type.REGIMEN'>
                      Your treatment kit is delivered
                    </div>

                  </div>
                </div>
              </section>
            </ng-container>
            <ng-container *ngIf="appConfig.Shared.Order.Stage.orderOutForDeliveryStages.includes(order?.get('stage'))">
              <out-for-delivery *swiperSlide [isRebrandingBanner]="isRebrandingBanner" [order]="order" (addEvents)="addEvents()"></out-for-delivery>
            </ng-container>
            <ng-container *ngIf="appConfig.Shared.Order.Stage.orderDispatchedStages.includes(order?.get('stage'))">
              <order-shipped *swiperSlide [isRebrandingBanner]="isRebrandingBanner" [order]="order" (addEvents)="addEvents()"></order-shipped>
            </ng-container>
            <ng-container *ngIf="appConfig.Shared.Order.Stage.orderPlacedStages.includes(order?.get('stage'))">
              <order-received *swiperSlide [isRebrandingBanner]="isRebrandingBanner" [order]="order" (addEvents)="addEvents()"></order-received>
            </ng-container>
            <ng-container *ngIf="appConfig.Shared.Order.Stage.orderPackedStages.includes(order?.get('stage'))">
              <packing-order *swiperSlide [isRebrandingBanner]="isRebrandingBanner" [order]="order" (addEvents)="addEvents()"></packing-order>
            </ng-container>
          </ng-container>
          <!--reply in ticket banner-->
          <ng-container *ngIf="replyInTicket">
            <doctor-reply *swiperSlide [isRebrandingBanner]="isRebrandingBanner" [userDoctor]="userDoctor" (viewTicket)="viewTicket()"></doctor-reply>
          </ng-container>
        </swiper>
        <div class="tw-absolute tw-text-center tw-bottom-[-10px]" id="swiper-pagination"></div>
      </div>
      <section class="banners bogoSale tw-mx-4 bdr-rad-15" *ngIf="user?.get('orderState') === appConfig.Shared.User.OrderState.DELIVERED && isAovSaleLive" (click)="openAovBanner()">
        <img [src]="aovBannerImage" />
      </section>
      <section class="banners bogoSale" *ngIf="userStatus.PAID && isBOGOSaleLive" (click)="openBogoBanner()">
        <img [src]="saleBannerImage" />
      </section>
      <section class="banners bogoSale" *ngIf="userStatus.PAID && isSaleLive" (click)="openSaleBanner()">
        <img [src]="bannerImage" />
      </section>
      <div *ngIf="rebrandedLevelFourBanners && (productsInCart?.length || recommendedItems?.length)" class="tw-w-full tw-px-4 tw-pt-4 tw-items-center tw-hidden">
        <div class="tw-text-200 tw-font-body tw-text-gray-800 tw-leading-[15px] tw-tracking-[2.04px] tw-uppercase tw-mr-4">Pending orders</div>
        <div class="tw-flex-grow tw-h-px tw-bg-gray-300"></div>
      </div>
      <div class="tw-relative levelFourBanners" *ngIf="(productsInCart?.length || recommendedItems?.length)">
        <ng-container *ngIf="voiceNoteByScript && userStatus.PAID">
          <app-voice-note-banner-script (goToCart)="goToCart($event)" [userDrImage]="userDrImage" [productsInCart]="productAddedByScript" (emitTrackEvent)="trackEventOne($event)" [user]="user"></app-voice-note-banner-script>
        </ng-container>
        <ng-container *ngIf="productsInCart.length > 1 && userStatus.PAID && !voiceNoteByScript && !showSuggestedForYouBanner">
          <app-complete-your-multiple-order (goToCart)="goToCart($event)" [userDrImage]="userDrImage" [levelFourBanners] = "rebrandedLevelFourBanners" [productsInCart]="productsInCart" [toolbarIcons]="toolbarIcons" [cartDiscount]="cartDiscount" [totalSp]="totalSP"></app-complete-your-multiple-order>
        </ng-container>
        <ng-container *ngIf="productsInCart.length === 1 && userStatus.PAID && !voiceNoteByScript && !showSuggestedForYouBanner">
          <complete-your-order (goToCart)="goToCart($event)" [levelFourBanners] = "rebrandedLevelFourBanners" [productsInCart]="productsInCart"  [cartDiscount]="cartDiscount" [totalSp]="totalSP" [userDrImage]="userDrImage"></complete-your-order>
        </ng-container>
        <ng-container *ngIf="showSuggestedForYouBanner && userStatus.PAID && !voiceNoteByScript">
          <suggested-for-you (goToCart)="addProductToCart($event)" [recommendedItems]="recommendedItems" [productsInCart]="productsInCart" [userDrImage]="userDrImage" [levelFourBanners] = "rebrandedLevelFourBanners" [cartDiscount]="cartDiscount" [totalSp]="totalSP"></suggested-for-you>
        </ng-container>
      </div>
      <!--slider-->
      <swiper id="main-swiper" class="tw-max-w-full tw-mb-6" [ngClass]="{'tw-overflow-visible': isMainSwiperMounted}" #swiperCardsBannerMain [config]="carouselConfig" (afterInit)="onAfterInitOfMainSwiper()">
        <!-- voice note banner-->
        <ng-container *ngIf="notListenedVoiceMessage">
          <app-voice-note-banner *swiperSlide [docImg]="voiceNoteBannerDetails.doctorDisplayImageURL || defaultDoctorImage" [voiceUrl]="voiceNoteBannerDetails.voiceUrl" [trackEventAudio]="voiceNoteBannerDetails.trackEventAudio" [OrderApproval]="OrderApproval" (trackVoiceEvent)="trackVoiceEvent(swiperCardsBannerMain,$event)"></app-voice-note-banner>
        </ng-container>
        <ng-container *ngFor="let regimen of regimens; let i = index">
          <ng-container *ngIf="!regimen.isPreparationTimePending && !regimen.active && !regimen.orderPlaced && regimen.class !== appConfig.Shared.Regimen.Class.BODY">
            <div *swiperSlide class="tw-flex tw-flex-col tw-gap-4">
              <div class="card tw-flex tw-flex-col tw-items-center tw-mx-4 tw-bg-white tw-rounded-[15px]" [ngClass]="{'tw-h-auto': !resetKitReadyBannerHeight, 'tw-h-0': resetKitReadyBannerHeight}" (click)="buyRegimen(regimen.class)">
                <regimen-kit-ready [class]="regimen.class" [coverImage]="regimen?.regimenCoverImage" [user]="this.user" [userName]="this.userName" [regimen]="regimen" [doctorImage]="userDrImage"></regimen-kit-ready>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <!-- re-acquisition banners-->
        <!--want to take dr checkup-->
        <ng-container *ngIf="reAcquisitionBannerType == 'reAcquisitionBannerReady'">
          <want-dr-checkup *swiperSlide (openReAcquisitionPage)="openReAcquisitionPage()"></want-dr-checkup>
        </ng-container>
        <!-- waiting for doctor call-->
        <ng-container *ngIf="reAcquisitionBannerType === 'waitingForReAcquisitionCall'">
          <doctor-call *swiperSlide></doctor-call>
        </ng-container>
        <!-- missed doctor call-->
        <ng-container *ngIf="reAcquisitionBannerType === 'missedReAcquisitionCall'">
          <missed-doctor-call *swiperSlide (openReAcquisitionPage)="openReAcquisitionPage()"></missed-doctor-call>
        </ng-container>
        <!-- FOLLOWUP BANNERS along with Level 2 banners-->
        <!-- Follow up report created-->
        <ng-container *ngIf="followUpReport">
          <ng-container *ngIf="!levelTwoBannerExperiment">
              <report-ready *swiperSlide [followUpReport]="followUpReport" (openFollowUpReport)="openFollowUpReport()"></report-ready>
            </ng-container>
            <ng-container *ngIf="levelTwoBannerExperiment">
              <report-is-ready *swiperSlide (openFollowUpReport)="openFollowUpReport()"></report-is-ready>
            </ng-container>
          </ng-container>
          <!-- Checkup is scheduled-->
          <ng-container *ngIf="selectedFollowUpBanner === 'followUpScheduled'  && !levelTwoBannerExperiment">
            <checkup-scheduled *swiperSlide [followUp]="followUp" (trackEvent)="trackEvent('SCHEDULED_FOLLOWUP_BANNER_CLICKED')"></checkup-scheduled>
          </ng-container>
          <!-- Checkup is scheduled on next two days-->
          <ng-container *ngIf="selectedFollowUpBanner === 'followUpScheduledInTwoDays'">
            <ng-container *ngIf="!levelTwoBannerExperiment">
              <waiting-for-photo *swiperSlide [followUp]="followUp" (startFollowUp)="startFollowUp('TAKE_FOLLOWUP_BANNER_CLICKED')"></waiting-for-photo>
            </ng-container>
            <ng-container *ngIf="levelTwoBannerExperiment">
              <checkup-due-banner *swiperSlide (startFollowUp)="startFollowUp('ANYTIME_FOLLOWUP_BANNER')"></checkup-due-banner>
            </ng-container>
          </ng-container>
          <!-- anytime followUpEnabled-->
          <ng-container *ngIf="selectedFollowUpBanner === 'anyTimeFollowUpEnabled'">
            <ng-container *ngIf="!levelTwoBannerExperiment">
              <checkup-anytime *swiperSlide [followUp]="followUp" (startFollowUp)="startFollowUp('ANYTIME_FOLLOWUP_BANNER')"></checkup-anytime>
            </ng-container>
            <ng-container *ngIf="levelTwoBannerExperiment">
              <checkup-due-banner *swiperSlide (startFollowUp)="startFollowUp('ANYTIME_FOLLOWUP_BANNER')"></checkup-due-banner>
            </ng-container>
          </ng-container>
          <!-- followUp waitingForImage-->
          <ng-container *ngIf="selectedFollowUpBanner === 'followUpWaitingForImage'">
            <ng-container *ngIf="!levelTwoBannerExperiment">
              <photo-blur *swiperSlide (startFollowUp)="startFollowUp('WAITING_FOR_IMAGE_BANNER_CLICKED')"></photo-blur>
            </ng-container>
            <ng-container *ngIf="levelTwoBannerExperiment">
              <requesting-photo *swiperSlide (startFollowUp)="startFollowUp('WAITING_FOR_IMAGE_BANNER_CLICKED')"></requesting-photo>
            </ng-container>
          </ng-container>
          <!-- followUp is ready waiting for report creation-->
          <ng-container *ngIf="selectedFollowUpBanner === 'followUpReadyWaitingForReport' && !festivalDelayBannerEnabled">
            <ng-container *ngIf="!levelTwoBannerExperiment">
              <report-process *swiperSlide (trackEvent)="trackEvent('WAITING_FOR_REPORT_BANNER_CLICKED')"></report-process>
            </ng-container>
            <ng-container *ngIf="levelTwoBannerExperiment">
              <report-getting-ready *swiperSlide></report-getting-ready>
            </ng-container>
          </ng-container>
          <!-- last followUp is skipped-->
          <ng-container *ngIf="selectedFollowUpBanner === 'lastFollowUpSkipped'">
            <ng-container *ngIf="!levelTwoBannerExperiment">
              <checkup-missed *swiperSlide (startFollowUp)="startFollowUp('LAST_FOLLOWUP_MISSED_BANNER')"></checkup-missed>
            </ng-container>
            <ng-container *ngIf="levelTwoBannerExperiment">
              <checkup-due-banner *swiperSlide (startFollowUp)="startFollowUp('ANYTIME_FOLLOWUP_BANNER')"></checkup-due-banner>
            </ng-container>
        </ng-container>
        <!--Unpaid state-->
        <!--Incomplete chat-->
        <ng-container *ngIf="consultationSession">
          <ng-container *ngIf="!newChatBannerExperiment && !newChatBannerWithImageExperiment">
            <div id="oldChatBanner"
                 class="card tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-orange-100"
                 *swiperSlide (click)="openConsultation()">
              <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full"
                   src="https://cdn.cureskin.com/app/img/dr-charu-cropped.png"/>
              <div class="tw-flex-1 tw-flex tw-flex-col tw-items-start tw-gap-2">
                <div>
                  <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black"
                     *ngIf="appConfig.Shared.Regimen.Class.FACE === concernClass" i18n="@@yourFaceChatIsIncomplete">
                    Your face chat is incomplete </p>
                  <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black"
                     *ngIf="appConfig.Shared.Regimen.Class.HAIR === concernClass" i18n="@@yourHairChatIsIncomplete">
                    Your hair chat is incomplete </p>
                  <p class="tw-font-body tw-text-200 tw-text-black tw-text-xs" i18n="@@completeNowToGetRegimen">
                    Answer a few questions more to get your kit </p>
                </div>
                <cs-button [class]="'primary btn-xxxs btn-xxxs-padding tw-px-2 btn-float'">
                  <cs-button-text>
                    <span i18n="@@startNow">Start Now</span>
                  </cs-button-text>
                </cs-button>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="newChatBannerExperiment && !newChatBannerWithImageExperiment && concernClass">
            <ng-container *ngIf="isFaceChatPending">
              <div *swiperSlide id="face" #face
                   class="tw-gap-4 tw-rounded-2xl tw-mx-4 tw-shadow-z1 tw-relative tw-bg-white tw-bg-grid-fade-rtl tw-bg-contain tw-bg-right-bottom tw-bg-no-repeat"
                   (click)="openChatFromNewIncompleteChatBanner($event)">
                <div
                  class="tw-flex tw-gap-2 tw-justify-start tw-items-center tw-bg-teal-100 tw-rounded-tl-4 tw-rounded-tr-4 tw-py-3 tw-px-4">
                  <span class="tw-w-2 tw-h-2 tw-bg-red-400 tw-rounded-full"></span>
                  <h1 class="tw-text-teal-600 tw-font-medium tw-text-200" i18n="@@yourSkinTreatment">Your Skin Treatment</h1>
                </div>
                <div class="tw-py-5 tw-px-5">
                  <div class="tw-text-300">
                    <div class="tw-flex tw-justify-start tw-items-center tw-mb-[1px]">
                          <span class="tw-flex tw-flex-1 tw-items-center">
                            <p class="" i18n="@@name">Name</p>:
                          </span>
                      <p class="tw-flex-1 tw-capitalize">{{ userFirstName }}</p>
                    </div>
                    <div class="tw-flex tw-justify-start tw-items-center tw-mb-[1px]">
                          <span class="tw-flex tw-flex-1 tw-items-center">
                            <p class="" i18n="@@age">Age</p>:
                          </span>
                      <p class="tw-flex-1">{{ userAge }} yrs</p>
                    </div>
                    <div class="tw-flex tw-justify-start tw-items-center">
                        <span class="tw-flex tw-flex-1 tw-items-center">
                          <p [ngClass]="{'tw-leading-4 tw-max-w-28 tw-text-wrap' : user.get('languagePreference') === 'ta'}" i18n="@@selectedConcern">Selected Concern</p>:
                        </span>
                      <p class="tw-flex-1 tw-font-bold tw-text-orange-400 tw-h-4 tw-leading-none tw-flex tw-justify-start tw-items-start">{{ faceDisplayConcern }}</p>
                    </div>
                  </div>
                </div>
                <div class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-pb-4">
                    <span i18n="@@continueChatFull"
                          class="tw-w-full tw-bg-teal-600 tw-text-white tw-py-2 tw-px-8 tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden">
                      Continue chat
                    </span>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isHairChatPending">
              <div *swiperSlide id="hair" #hair
                   class="tw-gap-4 tw-rounded-2xl tw-mx-4 tw-shadow-z1 tw-relative tw-bg-white tw-bg-grid-fade-rtl tw-bg-contain tw-bg-right-bottom tw-bg-no-repeat"
                   (click)="openChatFromNewIncompleteChatBanner($event)">
                <div
                  class="tw-flex tw-gap-2 tw-justify-start tw-items-center tw-bg-teal-100 tw-rounded-tl-4 tw-rounded-tr-4 tw-py-3 tw-px-4">
                  <span class="tw-w-2 tw-h-2 tw-bg-red-400 tw-rounded-full"></span>
                  <h1 class="tw-text-teal-600 tw-font-medium tw-text-200" i18n="@@yourHairTreatment">Your Hair Treatment</h1>
                </div>
                <div class="tw-py-5 tw-px-5">
                  <div class="tw-text-300">
                    <div class="tw-flex tw-justify-start tw-items-center tw-mb-[1px]">
                          <span class="tw-flex tw-flex-1 tw-items-center">
                            <p class="" i18n="@@name">Name</p>:
                          </span>
                      <p class="tw-flex-1 tw-capitalize">{{ userFirstName }}</p>
                    </div>
                    <div class="tw-flex tw-justify-start tw-items-center tw-mb-[1px]">
                          <span class="tw-flex tw-flex-1 tw-items-center">
                            <p class="" i18n="@@age">Age</p>:
                          </span>
                      <p class="tw-flex-1">{{ userAge }} yrs</p>
                    </div>
                    <div class="tw-flex tw-justify-start tw-items-center">
                        <span class="tw-flex tw-flex-1 tw-items-center">
                          <p [ngClass]="{'tw-leading-4 tw-max-w-28 tw-text-wrap' : user.get('languagePreference') === 'ta'}" i18n="@@selectedConcern">Selected Concern</p>:
                        </span>
                      <p class="tw-flex-1 tw-font-bold tw-text-orange-400 tw-h-4 tw-flex tw-justify-start tw-items-start tw-leading-none">{{ hairDisplayConcern }}</p>
                    </div>
                  </div>
                </div>
                <div class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-pb-4">
                    <span i18n="@@continueChatFull"
                          class="tw-w-full tw-bg-teal-600 tw-text-white tw-py-2 tw-px-8 tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden">
                      Continue chat
                    </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="newChatBannerWithImageExperiment && !newChatBannerExperiment && concernClass">
            <ng-container *ngIf="isFaceChatPending">
              <div *swiperSlide id="faceImg" #faceImg
                   class="tw-gap-4 tw-rounded-2xl tw-mx-4 tw-relative tw-bg-white tw-border tw-bg-grid-fade-rtl tw-bg-contain tw-bg-right-bottom tw-bg-no-repeat tw-border-teal-200 tw-overflow-hidden"
                   (click)="openChatFromNewIncompleteChatBanner($event)">
                <div
                  class="tw-flex tw-gap-2 tw-justify-start tw-items-center tw-bg-teal-100 tw-rounded-tl-2xl tw-rounded-tr-2xl tw-py-3 tw-px-4">
                  <span class="tw-w-2 tw-h-2 tw-bg-red-400 tw-rounded-full"></span>
                  <h1 class="tw-text-teal-600 tw-font-medium tw-text-200" i18n="@@yourSkinTreatment">Your Skin Treatment</h1>
                </div>
                <div class="tw-py-5 tw-px-5">
                  <div class="tw-text-400 tw-mb-1 tw-font-thin">
                    <p><span class="tw-capitalize">{{ userFirstName }}</span>, {{ userAge }} yrs</p>
                  </div>
                  <div class="tw-text-500 tw-font-bold tw-w-2/3 tw-z-10 tw-relative">
                    <h1 class="tw-leading-none">{{ faceDisplayConcern }}</h1>
                  </div>
                </div>
                <div class="tw-flex tw-justify-start tw-items-center tw-px-4 tw-pb-4">
                    <span i18n="@@continueChatFull"
                          class="tw-bg-teal-600 tw-text-white tw-py-2 tw-px-8 tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden">
                      Continue chat
                    </span>
                </div>
                <img src="https://cdn.cureskin.com/app/img/follow_up_doctor.png"
                     class="tw-absolute tw-bottom-0 tw-top-0 -tw-right-1 tw-z-0 tw-h-full">
              </div>
            </ng-container>
            <ng-container *ngIf="isHairChatPending">
              <div *swiperSlide id="hairImg" #hairImg
                   class="tw-gap-4 tw-rounded-2xl tw-mx-4 tw-relative tw-bg-white tw-border tw-bg-grid-fade-rtl tw-bg-contain tw-bg-right-bottom tw-bg-no-repeat tw-border-teal-200 tw-overflow-hidden"
                   (click)="openChatFromNewIncompleteChatBanner($event)">
                <div
                  class="tw-flex tw-gap-2 tw-justify-start tw-items-center tw-bg-teal-100 tw-rounded-tl-2xl tw-rounded-tr-2xl tw-py-3 tw-px-4">
                  <span class="tw-w-2 tw-h-2 tw-bg-red-400 tw-rounded-full"></span>
                  <h1 class="tw-text-teal-600 tw-font-medium tw-text-200" i18n="@@yourHairTreatment">Your Hair Treatment</h1>
                </div>
                <div class="tw-py-5 tw-px-5">
                  <div class="tw-text-400 tw-mb-1 tw-font-thin">
                    <p><span class="tw-capitalize">{{ userFirstName }}</span>, {{ userAge }} yrs</p>
                  </div>
                  <div class="tw-text-500 tw-font-bold tw-w-2/3 tw-z-10 tw-relative">
                    <h1 class="tw-leading-none">{{ hairDisplayConcern }}</h1>
                  </div>
                </div>
                <div class="tw-flex tw-justify-start tw-items-center tw-px-4 tw-pb-4">
                    <span i18n="@@continueChatFull"
                          class="tw-bg-teal-600 tw-text-white tw-py-2 tw-px-8 tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden ">
                      Continue chat
                    </span>
                </div>
                <img src="https://cdn.cureskin.com/app/img/follow_up_doctor.png"
                     class="tw-absolute tw-bottom-0 tw-top-0 -tw-right-1 tw-z-0 tw-h-full">
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <!--request for data-->
        <ng-container *ngIf="requestDataInTicket">
          <request-data *swiperSlide [userDoctor]="userDoctor" (viewTicket)="viewTicket()"></request-data>
        </ng-container>
        <!--festival banner-->
        <ng-container *ngIf="selectedFollowUpBanner === 'followUpReadyWaitingForReport' && festivalDelayBannerEnabled">
          <festive-banner *swiperSlide></festive-banner>
        </ng-container>
        <div class="cardHolder flex flex-row tw-gap-2" *ngIf="userStatus.PAID">
          <div class="card w50 tw-px-4 tw-py-2 bdr-rad-10" (click)="trackEventAndNavigateTo('HOME_INSTRUCTIONS', '/user?tab=regimen')">
            <img class="w30 rounded" src="https://cdn.cureskin.com/app/img/instruction.gif" />
            <div class="tw-flex tw-justify-between tw-items-center mT10">
              <div class="text face" i18n="@@instructions">Instructions</div>
              <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
            </div>
          </div>
          <div class="card w50 tw-px-4 tw-py-2 bdr-rad-10" (click)="trackEventAndNavigateTo('HOME_NEED_CHECKUP', '/user/checkup')" [ngClass]='{ "notify notify-new": tabUpdates.FOLLOWUP }'>
            <img class="w30 rounded" src="https://cdn.cureskin.com/app/img/checkup.gif" />
            <div class="tw-flex tw-justify-between tw-items-center mT10">
              <div class="text hair" i18n="@@checkupsNew">Checkups</div>
              <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
            </div>
          </div>
        </div>
      </swiper>
      <div class="tw-mb-2" *ngIf="swiperCardsBannerMain?.slides?.length"></div>
      <app-regimen-offer-product-banner *ngIf="(newRegimenPricingExperiment && (consultationSession || latestUnBoughtRegimen)) || latestUnBoughtRegimen?.extraProducts?.length" (click)="buyRegimen(regimen.class)" class="tw-mx-4 tw-mb-4 tw-box-border">
        <app-regimen-offer-tag><span i18n="@@limitedTimeOfferPouch">Limited time offer</span></app-regimen-offer-tag>
        <regimen-offer-product-title><span i18n="@@freePouch">+ Free Pouch</span></regimen-offer-product-title>
        <regimen-offer-product-caption><span i18n="@@withYourPurchase">with your purchase</span></regimen-offer-product-caption>
      </app-regimen-offer-product-banner>
      <ng-container *ngIf="shouldShowTakePhotoBanner">
        <take-photo-banner *ngIf="takePhotoBannerTrigerringStates?.orderWaitingForImage" class="tw-mx-4 tw-mb-4 tw-box-border" (click)="handleTakePhotoBannerClick()">
          <take-photo-banner-title> <span> Take latest photo now </span> </take-photo-banner-title>
          <take-photo-banner-caption> <span> as requested by your doctor </span> </take-photo-banner-caption>
        </take-photo-banner>
        <take-photo-banner *ngIf="takePhotoBannerTrigerringStates?.orderPlacedWithoutCheckup" class="tw-mx-4 tw-mb-4 tw-box-border" (click)="handleTakePhotoBannerClick()">
          <take-photo-banner-title> <span> Doctor needs your photo </span> </take-photo-banner-title>
          <take-photo-banner-caption> <span> to track your skin progress </span> </take-photo-banner-caption>
        </take-photo-banner>
        <take-photo-banner *ngIf="takePhotoBannerTrigerringStates?.firstRegimenWithoutCheckup" class="tw-mx-4 tw-mb-4 tw-box-border" (click)="handleTakePhotoBannerClick()">
          <take-photo-banner-title> <span> Doctor will need your photo </span> </take-photo-banner-title>
          <take-photo-banner-caption> <span> to track your skin progress </span> </take-photo-banner-caption>
        </take-photo-banner>
      </ng-container>
      <div class="tw-mb-4">
        <n-p-s-banner *ngIf="feedback" [feedback]="feedback"></n-p-s-banner>
      </div>
      <cross-regimen-banner></cross-regimen-banner>
      <!--take photo banner-->
      <ng-container *ngIf="!takePhotoBannerPositionBottom">
        <app-analysis-banner (analysisOfTheDay)="analysisOfTheDay($event)"></app-analysis-banner>
      </ng-container>
      <div class="tw-mb-4 tw-bg-white" *ngIf="!userStatus.PAID">
        <testimonials-widget [concernClass]="concernClass || regimenKit" [language]='user?.get("languagePreference") || appConfig.Shared.Languages.EN' [gender]='user.get("Gender") || appConfig.Shared.Gender.FEMALE' (redirectToTestimonialPage)="redirectToTestimonialsPage()"></testimonials-widget>
      </div>
      <insta-live-banner></insta-live-banner>
      <section class="banners" *ngIf="showClaimCashBanner" (click)='navigateTo("/user/wallet")'>
        <img [src]='imageService.getURL("https://cdn.cureskin.com/app/img/claim-cash.png")' />
      </section>
      <section class="notification" id="notification" *ngIf="notification.message" (click)="navigateTo(notification.link)">
        <div class="holder">
          <div class="icon">
            <em class="cs-icon-discount" *ngIf='notification.type === "OFFER"' [ngClass]="{ 'spin': isTabActive }"></em>
            <em class="cs-icon-notification" *ngIf='!notification.type || notification.type !== "OFFER"' [ngClass]="{ 'ring': isTabActive }"></em>
          </div>
          <div class="content">
            <span [innerHTML]="notification.message"></span>
          </div>
        </div>
      </section>
      <!--Doubts section-->
      <app-doubts-section *ngIf="!userStatus.PAID" [faceRegimenOrderPlaced]="faceRegimenOrderPlaced" [bannersTop]="bannersTop" [ImgSrc]="ImgSrc" (openPopUp)="openPopUp($event)" (knowAboutDoctors)="knowAboutDoctors($event)" (openBanner)="openBanner($event)"></app-doubts-section>
      <!-- weekly post purchase journey-->
      <!-- untill order is delivered-->
      <div class="tw-relative tw-mb-4 weeklyCard tw-bg-teal-100" *ngIf="isJourneyCardOne">
        <app-week-zero-journey [user]="user" [expectedDeliveryDate]="expectedDeliveryDate" [latestPlacedRegimenOrder]="latestPlacedRegimenOrder" [userDrImage]="userDrImage" [allocatedDoctor]="allocatedDoctor" [defaultDoctorImage]="defaultDoctorImage" [primaryConcern]="primaryConcern" [otherConcerns]="otherConcerns" (trackOrder)="trackOrder($event)" (openInstructions)="openInstructions($event)" (openDiet)="openDiet($event)" (openHelp)="openHelp($event)"></app-week-zero-journey>
      </div>
      <div class="tw-relative tw-mb-4 weeklyCard tw-bg-teal-100" *ngIf="isWeekOne && userStatus.PAID">
        <app-week1-part-one [user]="user" [pendingCheckInCall]="pendingCheckInCall" [faceRegimenOrderDelivered]="faceRegimenOrderDelivered" [latestFaceRegimenDelivered]="latestFaceRegimenDelivered" (openInstructions)="openInstructions($event)" (openStaticArticle)='openStaticArticle("68tmQuvkpb",$event)' (openHelp)="openHelp($event)" (openCheckup)="openCheckup($event)"></app-week1-part-one>
      </div>
      <div class="tw-relative tw-mb-4 weeklyCard tw-bg-teal-100" *ngIf="isJourneyCardTwo && pendingCheckInCall && userStatus.PAID">
        <app-week1-part-two [user]="user" [pendingCheckInCall]="pendingCheckInCall" (openInstructions)="openInstructions($event)" (openStaticArticle1)='openStaticArticle("7T2BaIQHxh",$event)' (openStaticArticle2)='openStaticArticle("6dH4XFVff4",$event)' (openHelp)="openHelp($event)"></app-week1-part-two>
      </div>
      <div class="tw-relative tw-mb-4 weeklyCard tw-bg-teal-100" *ngIf="isJourneyCardFour && userStatus.PAID">
        <app-week2-part-one [user]="user" [followUp]="followUp" (openInstructions)="openInstructions($event)" (openStaticArticle1)='openStaticArticle("hUUviTYk22",$event)' (openStaticArticle2)='openStaticArticle("7T2BaIQHxh",$event)'></app-week2-part-one>
      </div>
      <div class="tw-relative tw-mb-4 weeklyCard tw-bg-teal-100" *ngIf="isJourneyCardThree && userStatus.PAID">
        <app-week2-part-two [user]="user" [followUp]="followUp" [userDrImage]="userDrImage" [defaultDoctorImage]="defaultDoctorImage" (openStaticArticle1)='openStaticArticle("BoXTnEmmEr",$event)' (openStaticArticle2)='openStaticArticle("68tmQuvkpb",$event)' (openCheckup)="openCheckup($event)"></app-week2-part-two>
      </div>
      <!-- week 3 -->
      <div class="tw-relative tw-mb-4 weeklyCard tw-bg-teal-100" *ngIf="isWeekThreeJourneyCard && userStatus.PAID">
        <app-week3-part-one [user]="user" [followUp]="followUp" [userDrImage]="userDrImage" [defaultDoctorImage]="defaultDoctorImage" (openStaticArticle1)='openStaticArticle("BoXTnEmmEr",$event)' (openCheckup)="openCheckup($event)"></app-week3-part-one>
      </div>
      <!-- week 4 -->
      <div class="tw-relative tw-mb-4 weeklyCard tw-bg-teal-100" *ngIf="isWeekFourJourneyCard && userStatus.PAID">
        <app-week4-part-one [user]="user" [followUp]="followUp" [userDrImage]="userDrImage" [defaultDoctorImage]="defaultDoctorImage" (openStaticArticle1)='openStaticArticle("BoXTnEmmEr",$event)' (openCheckup)="openCheckup($event)"></app-week4-part-one>
      </div>
      <div id="nonStaticContent" class="tw-mb-36">
        <!--Diet plan-->
        <app-diet-plan [userStatus]="userStatus" (openDiet)="openDiet($event)"></app-diet-plan>
        <!--Analysis of the day-->
        <ng-container *ngIf="takePhotoBannerPositionBottom">
          <app-analysis-banner (analysisOfTheDay)="analysisOfTheDay($event)"></app-analysis-banner>
        </ng-container>
        <!--Refer and Earn banner-->
        <app-referral-banner [userStatus]="userStatus" (referBannerClick)="referBannerClick($event)"></app-referral-banner>
        <!--New game-->
        <app-quiz-banner [userStatus]="userStatus" [showGameBanner]="showGameBanner" (openGame)="openGame($event)"></app-quiz-banner>
        <div class="tw-relative tw-mb-5">
          <swiper id="main-swiper" class="tw-max-w-full tw-overflow-visible tw-mb-1" [config]="bottomCarouselConfig">
            <ng-container>
              <app-doctor-panel *swiperSlide (openDoctorList)="openDoctorList()"></app-doctor-panel>
            </ng-container>
            <ng-container>
              <app-testimonials-banner *swiperSlide (redirectToTestimonialsPage)="redirectToTestimonialsPage()"></app-testimonials-banner>
            </ng-container>
          </swiper>
          <div class="tw-absolute tw-text-center tw-mb-5" id="swiper-pagination"></div>
        </div>
        <!--FAQ section-->
        <app-faq-section></app-faq-section>
        <!--Contact us-->
        <app-explore-contact-banners [userStatus]="userStatus" [user]="user" (optedForSupport)="optedForSupport($event)" (exploreSectionRedirect)="exploreSectionRedirect($event)"></app-explore-contact-banners>
      </div>
    </div>
  </div>
</div>
<!--sticky bar for cart items-->
<app-sticky-bar [showStickyBars$]="showStickyBars$" [userStatus]="userStatus" [bannerContainer]="bannerContainer" [order]="order" [followUp]="followUp" [defaultDoctorImage]="defaultDoctorImage" [toolbarIcons]="toolbarIcons" [user]="user" (onSlideChange)="onSlideChange($event)" (checkoutOrder)="checkoutOrder()" (onCancelClick)="onCancelClick()" (startFollowUp)="startFollowUp($event)" (kitUrl)="getKitImage()" (goToCartNav)="goToCartNav()"></app-sticky-bar>
<div class="navigation-drawer">
  <!-- <div class="drawer-bg" (click)="toggleMenu(false)" [ngClass]="{ 'open': isMenuOpen, 'close': !isMenuOpen, 'hide': isMenuOpen === undefined }"></div> -->
  <div class="drawer-content trans-x-hide" swipe="swipe" (coordinates)="swipeCoordinates($event)" [ngClass]='{ "slideFromLeft": isMenuOpen, "slideToLeft": isMenuOpen === false }' (click)="toggleMenu(false)">
    <div class="holder w100">
      <user-profile (onPopupToggle)="makeComponentActive(!$event)"></user-profile>
    </div>
  </div>
</div>
