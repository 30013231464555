export const concernsTranslation: { [key: string]: string }[] = [
  {
    en: 'Dark Circles',
    hi: 'काले घेरे',
    mr: 'काळी वर्तुळे',
    te: 'నల్లటి వలయాలు',
    kn: 'ಡಾರ್ಕ್ ಸರ್ಕಲ್ಸ್',
    ta: 'கரு வளையங்கள்',
    bn: 'ডার্ক সার্কেল',
    ml: 'കറുത്ത വളയങ്ങൾ',
  },
  {
    en: 'Acne Scars',
    hi: 'एक्ने के निशान',
    mr: 'मुरुमांचे चट्टे',
    te: 'మొటిమల మచ్చలు',
    kn: 'ಏಕ್ನೆ ಸ್ಕಾರ್ಸ್',
    ta: 'முகப்பரு வடுக்கள்',
    bn: 'ব্রণর দাগ',
    ml: 'മുഖക്കുരുവിന്റെ പാടുകൾ',
  },
  {
    en: 'Other Scars',
    hi: 'अन्य निशान',
    mr: 'इतर डाग/ इतर चट्टे',
    te: 'ఇతర మచ్చలు',
    kn: 'ಇತರ ಗುರುತುಗಳು',
    ta: 'பிற வடுக்கள்',
    bn: 'অন্য ক্ষতচিহ্ন',
    ml: 'മറ്റ് പാടുകൾ',
  },
  {
    en: 'Puffy Eyes',
    hi: 'सूजी हुई आँखें',
    mr: 'सुजलेले डोळे',
    te: 'ఉబ్బెత్తు కళ్ళు',
    kn: 'ಪಫಿ ಐಸ್',
    ta: 'வீக்கமான கண்கள்',
    bn: 'ফোলা চোখ',
    ml: 'വീർത്ത കണ്ണുകൾ',
  },
  {
    en: 'Perioral Pigmentation',
    hi: 'पेरिओरल पिगमेंटेशन',
    mr: 'पेरिओरल पिगमेंटेशन',
    te: 'పెరియోరల్ పిగ్మెంటేషన్',
    kn: 'ಪೆರಿಯೊರಲ್ ಪಿಗ್ಮೆಂಟೇಶನ್',
    ta: 'பீரியோரல் பிக்மென்டேஷன்',
    bn: 'পেরিওরাল পিগমেন্টেশন',
    ml: 'മുഖത്തിന് ചുറ്റുമുള്ള ചർമ്മത്തിന്റെ നിറം മങ്ങൽ',
  },
  {
    en: 'Freckles',
    hi: 'झाइयाँ',
    mr: 'डाग',
    te: 'మచ్చలు',
    kn: 'ನಸುಕಂದು ಮಚ್ಚೆಗಳು',
    ta: 'முகப்புள்ளிகள்',
    bn: 'ফ্রেকলস',
    ml: 'പുള്ളികൾ',
  },
  {
    en: 'Facial Hair',
    hi: 'चेहरे पर बाल',
    mr: 'चेहऱ्यावरील केस ',
    te: 'ముఖం పై వెంట్రుకలు',
    kn: 'ಮುಖದ ಕೂದಲು',
    ta: 'முகத்தில் முடி வளர்ச்சி',
    bn: 'মুখের লোম',
    ml: 'മുഖത്തിലെ രോമം',
  },
  {
    en: 'Inflammatory Acne',
    hi: 'इंफ्लेमेटरी एक्ने',
    mr: 'इंफ्लेमेटरी एक्ने',
    te: 'ఇన్ఫ్లమేటరీ యాక్నే',
    kn: 'ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ',
    ta: 'இன்ஃப்ளமேட்டரி அக்னே',
    bn: null,
    ml: null,
  },
  {
    en: 'Comedones',
    hi: 'कॉमेडोन',
    mr: 'कॉमेडोन',
    te: 'కామెడోన్స్',
    kn: 'ಕಾಮೆಡೋನ್ಸ್',
    ta: 'கொமெடோன்கள்',
    bn: null,
    ml: null,
  },
  {
    en: 'Dark Spots',
    hi: 'डार्क स्पॉट्स',
    mr: 'डार्क स्पॉट्स',
    te: 'డార్క్ స్పాట్స్',
    kn: 'ಡಾರ್ಕ್ಸ್ ಸ್ಪಾಟ್ಸ್',
    ta: 'கரும்புள்ளிகள்',
    bn: null,
    ml: null,
  },
  {
    en: 'Melasma',
    hi: 'मेलास्मा',
    mr: 'मेलास्मा',
    te: 'మెలస్మా',
    kn: 'ಮೆಲಸ್ಮಾ',
    ta: 'மெலஸ்மா',
    bn: null,
    ml: null,
  },
  {
    en: 'Temple Baldness',
    hi: 'माथे के किनारों का गंजापन',
    mr: 'माथ्यावरचे/कानाबाजूचे टक्कल पडणे',
    te: 'నెత్తి భాగాలలో బట్టతల',
    kn: 'ಗಂಡಸ್ಥಲ ಬಕ್ಕತನ',
    ta: 'நெற்றிப் பக்கங்களில் வழுக்கை',
    bn: null,
    ml: null,
  },
  {
    en: 'Anti-aging / Wrinkles',
    hi: 'एंटी एजिंग / झुर्रियाँ',
    mr: 'अँटी एजिंग / सुरकुत्या',
    te: 'యాంటీ ఏజింగ్ / ముడతలు',
    kn: 'ಆಂಟಿ-ಏಜಿಂಗ್ / ರಿಂಕಲ್ಸ್',
    ta: 'ஆண்டி-ஏஜிங் / சுருக்கங்கள்',
    bn: 'অ্যান্টি-এজিং / বলিরেখা',
    ml: 'പ്രായം-കുറയ്ക്കുന്ന / ചുളിവുകൾ',
  },
  {
    en: 'Sun Tanning',
    hi: 'टैनिंग / धूप से काली हुई त्वचा',
    mr: 'टॅनिंग / उन्हामुळे काळी पडलेली त्वचा',
    te: 'సన్ టానింగ్ / సూర్యరశిమి చే నల్లగా మారిన చర్మం',
    kn: 'ಸನ್ ಟಾನಿಂಗ್',
    ta: 'சன் டேனிங் / சூரிய ஒளியில் சருமம் கருமையாதல் / சருமம் கருத்தல்',
    bn: 'সূর্যের থেকে ট্যান পড়ে যাওয়া',
    ml: 'വെയിൽ മൂലമുള്ള നിറം മങ്ങൽ',
  },
  {
    en: 'Dull / Tanned Skin',
    hi: 'सुस्त बेजान / धूप से काली हुई त्वचा',
    mr: 'उन्हामुळे काळी पडलेली / निस्तेज त्वचा',
    te: 'నిస్తేజమైన / సూర్యరశిమి చే నల్లగా మారిన చర్మం',
    kn: 'ಡಲ್ ಸ್ಕಿನ್ / ಸನ್ ಟಾನಿಂಗ್',
    ta: 'பொலிவற்ற சருமம் / சூரிய ஒளியில் சருமம் கருமையாதல்',
    bn: 'নিস্তেজ প্রাণহীন ত্বক / সূর্যের থেকে ট্যান পড়ে যাওয়া',
    ml: 'മങ്ങിയ ചർമ്മം / വെയിൽ മൂലമുള്ള നിറം മങ്ങൽ',
  },
  {
    en: 'Open Pores',
    hi: 'ओपन पोर्स',
    mr: 'ओपन पोर्स',
    te: 'ఓపెన్ పోర్స్',
    kn: 'ಓಪನ್ ಪೋರ್ಸ್',
    ta: 'திறந்த சரும துளைகள்',
    bn: null,
    ml: null,
  },
  {
    en: 'Rosacea',
    hi: 'रॉसेसिया',
    mr: 'रोसासिआ',
    te: 'రోసేసియా',
    kn: 'ರೋಸೇಸಿಯಾ',
    ta: 'ரோசாசியா',
    bn: null,
    ml: null,
  },
  {
    en: 'Fine Lines',
    hi: 'फाइन लाइन्स',
    mr: 'फाइन लाईन्स',
    te: 'ఫైన్ లైన్స్',
    kn: 'ಫೈನ್ ಲೈನ್ಸ್',
    ta: 'சுருக்கங்கள்',
    bn: null,
    ml: null,
  },
  {
    en: 'Blackheads',
    hi: 'ब्लैकहेड्स',
    mr: 'ब्लॅकहेड्स',
    te: 'బ్లాక్ హెడ్స్',
    kn: 'ಬ್ಲ್ಯಾಕ್ ಹೆಡ್ಸ್',
    ta: 'கரும்புள்ளிகள்',
    bn: null,
    ml: null,
  },
  {
    en: 'Whiteheads',
    hi: 'वाइटहेड्स',
    mr: 'व्हाईटहेड्स',
    te: 'వైట్ హెడ్స్',
    kn: 'ವೈಟ್ ಹೆಡ್ಸ್',
    ta: 'வயிட்ஹெட்ஸ்',
    bn: null,
    ml: null,
  },
  {
    en: 'Dull skin',
    hi: 'सुस्त बेजान त्वचा',
    mr: 'निस्तेज त्वचा',
    te: 'నిస్తేజమైన చర్మం',
    kn: 'ಡಲ್ ಸ್ಕಿನ್',
    ta: 'பொலிவற்ற சருமம்',
    bn: 'নিস্তেজ প্রাণহীন ত্বক',
    ml: 'മങ്ങിയ ചർമ്മം',
  },
  {
    en: 'Tanned Skin',
    hi: 'धूप से काली हुई त्वचा',
    mr: 'उन्हामुळे काळी पडलेली त्वचा',
    te: 'సూర్యరశిమి చే నల్లగా మారిన చర్మం',
    kn: 'ಸನ್ ಟಾನಿಂಗ್',
    ta: 'சூரிய ஒளியில் சருமம் கருமையாதல்',
    bn: 'সূর্যের থেকে ট্যান পড়ে যাওয়া',
    ml: 'വെയിൽ മൂലമുള്ള നിറം മങ്ങൽ',
  },
];
