import { Component } from '@angular/core';

@Component({
  selector: 'take-photo-banner',
  templateUrl: './take-photo-banner.html',
  styles: ':host { @apply tw-flex tw-justify-between tw-p-4 tw-bg-teal-700 tw-rounded-4 tw-shadow-z4} ',
})

export class TakePhotoBannerComponent {
}
